import * as React from "react";
import { ContentContainer, PageTitle, RootContainer, TitleContainer } from "@components/ui/PageBaseComponents";
import { Department, Segment, StablishmentType, StoreFormValues } from "@util/interfaces";
import Api from "@services/Api";
import { toastError, toastMessage } from "@util/functions";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { InputLabel } from "@components/ui/Typography";
import Divider from "@components/ui/Divider";
import { fetchStores } from "@redux/AppData.store";
import { useAppDispatch } from "@redux/index";
import { useHistory } from "react-router";
import { cpf } from "cpf-cnpj-validator";
import { cnpj } from "cpf-cnpj-validator";
import Select from "@components/ui/Select";

const AddStoreScreen: React.FC = () => {
  const [departments, setDepartments] = React.useState([]);
  const [segments, setSegments] = React.useState([]);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleSubmit = async (values: StoreFormValues, actions: FormikHelpers<StoreFormValues>) => {
    try {
      if (values.cpf && !cpf.isValid(values.cpf)) return toastMessage("CPF inválido", "error");
      if (values.cnpj && !cnpj.isValid(values.cnpj)) return toastMessage("CNPJ inválido", "error");

      const body = {
        ...values,
        segments: values.segments.map((segment: any) => segment.value),
        departments: values.departments.map((department: any) => department.value),
        phone_1: values.phone_1_number,
        phone_2: values.phone_2_number,
        phone_1_is_whatsapp:
          values.phone_1_is_whatsapp === true || values.phone_1_is_whatsapp === "true" ? true : false,
        phone_2_is_whatsapp:
          values.phone_2_is_whatsapp === true || values.phone_2_is_whatsapp === "true" ? true : false,
        is_active: values.is_active === true || values.is_active === "true" ? true : false,
        accepts_card: values.accepts_card === true || values.accepts_card === "true" ? true : false,
        has_delivery: values.has_delivery === true || values.has_delivery === "true" ? true : false,
        patrimonies: values.patrimonies.map((address) => ({ ...address })),
      };

      const { data } = await Api.post(`/store`, body);
      toastMessage("Estabelecimento criado", "success");
      dispatch(fetchStores());
      history.push(`/estabelecimentos/${data.store_id}/editar`);
    } catch (error) {
      toastError(error);
    }
  };

  const fecthData = async () => {
    try {
      const departmentsResponse = await Api.get(`/department`);
      const segmentsResponse = await Api.get(`/segment`);
      setDepartments(
        departmentsResponse.data.departments?.map?.((department: Department) => ({
          value: department.id,
          label: department.name,
        }))
      );
      setSegments(
        segmentsResponse.data.segments?.map?.((segment: Segment) => ({
          value: segment.id,
          label: `${segment.department?.name} - ${segment.name}`,
        }))
      );
    } catch (error) {
      toastError(error);
    }
  };

  React.useEffect(() => {
    fecthData();
  }, []);

  const initialValues: StoreFormValues = {
    name: "",
    legal_name: "",
    patrimony_code: "",
    email: "",
    cpf: "",
    cnpj: "",
    instagram: "",
    site: "",
    accepts_card: "true",
    has_delivery: "true",
    is_active: "true",
    phone_1_is_whatsapp: "true",
    phone_1_number: "",
    phone_2_is_whatsapp: "true",
    phone_2_number: "",
    patrimonies: [],
    departments: [],
    segments: [],
    stablishment_type: StablishmentType.STORE,
    stablishment_custom_address: "",
  };

  return (
    <RootContainer>
      <TitleContainer>
        <PageTitle>Cadastrar estabelecimento</PageTitle>
      </TitleContainer>
      <ContentContainer>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ values, setFieldValue }) => (
            <Form>
              <div className="row m-0">
                <div className="col-12 mb-3">
                  <PageTitle style={{ fontSize: 24 }}>Dados do estabelecimento</PageTitle>
                </div>
                <div className="mb-4 col-md-8">
                  <InputLabel>Nome fantasia</InputLabel>
                  <Field name="name" className="form-control" placeholder="Digite o nome do estabelecimento" required />
                </div>

                <div className="mb-4 col-md-4">
                  <InputLabel>Tipo</InputLabel>
                  <Field name="stablishment_type" as="select" className="form-control">
                    <option value={"store"}>Loja</option>
                    <option value={"hotel"}>Hotel</option>
                    <option value={"kiosk"}>Quiosque</option>
                    <option value={"restaurant"}>Restaurante</option>
                  </Field>
                </div>

                <div className="mb-4 col-md-8">
                  <InputLabel>Razão social</InputLabel>
                  <Field name="legal_name" className="form-control" placeholder="Digite a razão social do estabelecimento" />
                </div>

                <div className="mb-4 col-md-4">
                  <InputLabel>Código do patrimônio</InputLabel>
                  <Field
                    name="patrimony_code"
                    className="form-control"
                    placeholder="Digite o código patrimonial do estabelecimento"
                  />
                </div>

                <div className="mb-4 col-md-6">
                  <InputLabel>CPF</InputLabel>
                  <Field name="cpf" className="form-control" placeholder="Digite o CPF do responsável" />
                </div>
                <div className="mb-4 col-md-6">
                  <InputLabel>CNPJ</InputLabel>
                  <Field name="cnpj" className="form-control" placeholder="Digite o CNPJ do estabelecimento" />
                </div>
                <div className="mb-4 col-md-4">
                  <InputLabel>Email</InputLabel>
                  <Field
                    name="email"
                    type="email"
                    className="form-control"
                    placeholder="Digite o email do estabelecimento"
                  />
                </div>
                <div className="mb-4 col-md-4">
                  <InputLabel>Instagram</InputLabel>
                  <Field
                    name="instagram"
                    className="form-control"
                    placeholder="Digite o instagram do estabelecimento"
                  />
                </div>
                <div className="mb-4 col-md-4">
                  <InputLabel>Site</InputLabel>
                  <Field name="site" className="form-control" placeholder="Digite o site do estabelecimento" />
                </div>
                <div className="mb-4 col-md-6 row no-gutters p-0 m-0">
                  <div className="col-8">
                    <InputLabel>Telefone (1)</InputLabel>
                    <Field
                      name="phone_1_number"
                      className="form-control"
                      placeholder="Digite o telefone (1) do estabelecimento"
                    />
                  </div>
                  <div className="col-4">
                    <InputLabel>Whatsapp</InputLabel>
                    <Field name="phone_1_is_whatsapp" as="select" className="form-control">
                      <option value={"true"}>Sim</option>
                      <option value={"false"}>Não</option>{" "}
                    </Field>
                  </div>
                </div>
                <div className="mb-4 col-md-6 row no-gutters p-0 m-0">
                  <div className="col-8">
                    <InputLabel>Telefone (2)</InputLabel>
                    <Field
                      name="phone_2_number"
                      className="form-control"
                      placeholder="Digite o telefone (2) do estabelecimento"
                    />
                  </div>
                  <div className="col-4">
                    <InputLabel>Whatsapp</InputLabel>
                    <Field name="phone_2_is_whatsapp" as="select" className="form-control">
                      <option value={"true"}>Sim</option>
                      <option value={"false"}>Não</option>{" "}
                    </Field>
                  </div>
                </div>
                <div className="mb-4 col-md-4">
                  <InputLabel>Ativo</InputLabel>
                  <Field name="is_active" as="select" className="form-control">
                    <option value={"true"}>Sim</option>
                    <option value={"false"}>Não</option>{" "}
                  </Field>
                </div>
                <div className="mb-4 col-md-4">
                  <InputLabel>Aceita cartão</InputLabel>
                  <Field name="accepts_card" as="select" className="form-control">
                    <option value={"true"}>Sim</option>
                    <option value={"false"}>Não</option>{" "}
                  </Field>
                </div>
                <div className="mb-4 col-md-4">
                  <InputLabel>Faz delivery</InputLabel>
                  <Field name="has_delivery" as="select" className="form-control">
                    <option value={"true"}>Sim</option>
                    <option value={"false"}>Não</option>{" "}
                  </Field>
                </div>
                <div className="mb-4 col-md-6">
                  <InputLabel>Departamentos</InputLabel>
                  <Select
                    label="Departamentos"
                    placeholder={"Selecione os departamentos"}
                    values={values.departments.map((department) => department.id)}
                    options={departments}
                    isMulti={true}
                    closeMenuOnSelect={false}
                    onChange={(selectedValues) => {
                      setFieldValue("departments", selectedValues);
                    }}
                  />
                </div>
                <div className="mb-4 col-md-6">
                  <InputLabel>Segmentos</InputLabel>
                  <Select
                    label="Segmentos"
                    placeholder={"Selecione os segmentos"}
                    values={values.segments.map((segment) => segment.id)}
                    options={segments}
                    isMulti={true}
                    closeMenuOnSelect={false}
                    onChange={(selectedValues) => {
                      setFieldValue("segments", selectedValues);
                    }}
                  />
                </div>
                <div className="mb-4 col-md-6">
                  <InputLabel>Endereço (Hotel, quiosque e restaurante)</InputLabel>
                  <Field
                    name="stablishment_custom_address"
                    className="form-control"
                    placeholder="Digite o endereço do estabelecimento"
                  />
                </div>

                <div className="mb-4 col-md-6">
                  <InputLabel>Código da loja no marketplace</InputLabel>
                  <Field
                    name="catalog_code"
                    className="form-control"
                    placeholder="Digite o código da loja no marketplace"
                  />
                </div>
                <div className="my-2">
                  <Divider />
                </div>
                <div className="col-12">
                  <button className="btn btn-primary mt-2 mt-md-0" type="submit">
                    enviar
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </ContentContainer>
    </RootContainer>
  );
};

export default AddStoreScreen;
